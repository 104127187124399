<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  import { getCompany, updateCompany, getCompanyOrders } from '@/api/Companies.js'
  import { Company } from "@/models/Company";
  import { Subscription } from "@/models/Subscription";
  import { Order } from "@/models/Order";

  import orders from "@/router/views/companies/tabs/orders";
  import creditcards from "@/router/views/companies/tabs/creditcards";
  import limits from "@/router/views/companies/tabs/limits";
  import contacts from "@/router/views/companies/tabs/contacts";

  /**
   * Basic-table component
   */
  export default {
    page: {
      title: "Empresas",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, orders, limits, creditcards, contacts },
    data() {
      return {
        title: "Empresas",
        items: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Empresas",
            href: "/companys",
          },
          {
            text: "Detalhes",
            active: true,
          },
        ],
        company: new Company(),
        subscription: new Subscription(),
        orders: [],
        ui:{
          forms: {
            company: {
              disabled: false
            }
          }
        }
      };
    },
    created () {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this;
        getCompany(this.$route.params.id).then(response => {
          vm.company = new Company(response);
          vm.subscription = new Subscription(response.subscription);
        });
        
        getCompanyOrders(this.$route.params.id).then(response => {
          vm.orders = response.map(item => {
            return new Order(item)
          });
        });
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
      handleOk(bvModalEvt){
        bvModalEvt.preventDefault()

        this.$refs.observer.validate().then(result => {
          if(result){
            this.updateCompany()
            this.$bvModal.hide('modal-company')
          }
        });
      },
      updateCompany(){
        let vm = this

        if(!this.ui.forms.company.disabled){
          this.ui.forms.company.disabled = true

          this.$awn.async(
            updateCompany(this.company),
            function () {
              vm.$awn.success('Empresa atualizada com sucesso.')
              vm.ui.forms.company.disabled = false
            },
            function () {
              vm.ui.forms.company.disabled = false
              vm.$awn.warning('Ocorreu um erro ao excutar a operação. Favor verificar os dados inseridos e tentar novamente.')
            }
          )
        }
      }
    },
    computed: {
      hasOrders: function () {
        return this.orders.length > 0
      }
    }
  };
</script>

<template>
  <Layout>
    <PageHeader
      :title="company.name || title"
      :items="items"
    />
    
    <div class="card">
      <div class="card-body">
        <h4 class="mb-3 font-size-18">
          Detalhes
        </h4>
  
        <div class="table-responsive mb-0">
          <table class="table">
            <tbody>
            <tr>
              <th scope="row">
                Nome Fantasia:
              </th>
              <td>
                {{ company.name }}
              </td>
              <th scope="row">
                Razão Social:
              </th>
              <td>{{ company.business_name }}</td>
              <th scope="row">
                CPF/CNPJ:
              </th>
              <td>{{ company.nif }}</td>
            </tr>
            <tr>
              <th scope="row">
                E-mail:
              </th>
              <td>{{ company.email }}</td>
              <th scope="row">
                Telefone:
              </th>
              <td>{{ company.phone }}</td>
              <th scope="row">
                Criada em:
              </th>
              <td>{{ $d(company.created_at, 'short')}}</td>
            </tr>
            <tr>
              <th scope="row">
                Endereço:
              </th>
              <td colspan="5" v-html="company.address"></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    <div class="card">
      <div class="card-body">
        <h4 class="mb-3 font-size-18">
          Assinatura
        </h4>
      
        <div class="table-responsive mb-0">
          <table class="table">
            <tbody>
            <tr>
              <th scope="row">
                Plano:
              </th>
              <td>
                {{ $t(`content.packages.${subscription.package.slug}`) }}
              </td>
              <th scope="row">
                Agentes:
              </th>
              <td>{{ subscription.agent }} <small class="text-muted">({{ subscription.aditional_agent }} adicionais)</small></td>
              <th scope="row">
                Vencimento
              </th>
              <td>{{ $d(subscription.due,'short') }}</td>
              <td v-if="!subscription.suspended">
                <span v-if="!subscription.is_due" class="badge badge-success">Ativa</span>
                <span v-else class="badge badge-warning">Vencida</span>
              </td>
              <td v-else>
                <span class="badge badge-danger">Suspensa</span>
              </td>
              <td class="min">
                <button
                  type="button"
                  class="btn btn-outline-primary btn-sm btn-rounded"
                  @click="$router.push(`/subscriptions/${subscription.id}`)"
                >
                  <i class="bx bx-search" />
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    <div class="card">
      <div class="card-body">
        <b-tabs
          justified
          nav-class="nav-tabs-custom"
          content-class="p-0"
        >
          <b-tab active>
            <template v-slot:title>
              <span class="d-none d-sm-inline-block">Faturas</span>
            </template>
            <orders :companyId="$route.params.id"></orders>
            
          </b-tab>
          <b-tab lazy>
            <template v-slot:title>
              <span class="d-none d-sm-inline-block">Contatos</span>
            </template>
            <contacts :companyId="$route.params.id"></contacts>
          </b-tab>
          <b-tab lazy>
            <template v-slot:title>
              <span class="d-none d-sm-inline-block">Limites</span>
            </template>
            <limits :companyId="$route.params.id"></limits>
          </b-tab>
          <b-tab lazy>
            <template v-slot:title>
              <span class="d-none d-sm-inline-block">Cartões de Crédito</span>
            </template>
            <creditcards :companyId="$route.params.id"></creditcards>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  
    <b-button
      variant="primary"
      size="lg"
      class="btn-rounded-fixed"
      pill
      @click="$bvModal.show('modal-company')">
      <i class="bx bx-edit"></i>
    </b-button>
  
    <b-modal
      id="modal-company"
      centered
      title="Editar Empresa"
      title-class="font-18"
      :cancel-title="'Cancelar'"
      :ok-title="'Salvar'"
      @ok="handleOk"
      size="lg"
    >
      
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form
          @submit.stop.prevent="handleSubmit(updateCompany)"
          class="form-horizontal"
        >
          <div class="row">
            <div class="col-md-6">
              <validation-provider
                name="Nome Fantasia"
                :rules="{ required: true }"
                v-slot="name"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="Nome Fantasia"
                  label-for="input-company-name"
                >
                  <b-form-input
                    v-model="company.name"
                    id="input-company-name"
                    name="name"
                    :state="getValidationState(name)"
                  />
                  <b-form-invalid-feedback>{{ name.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            
            <div class="col-md-6">
              <validation-provider
                name="Razão Social"
                :rules="{ required: true }"
                v-slot="business_name"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="Razão Social"
                  label-for="input-company-business-name"
                >
                  <b-form-input
                    v-model="company.business_name"
                    id="input-company-business-name"
                    name="business_name"
                    :state="getValidationState(business_name)"
                  />
                  <b-form-invalid-feedback>{{ business_name.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            
            <div class="col-md-6">
              <validation-provider
                name="CPF/CNPJ"
                :rules="{ required: true, min: 11, max: 14, nif: true }"
                v-slot="nif"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="CPF/CNPJ"
                  label-for="input-company-nif"
                >
                  <b-form-input
                    id="input-company-nif"
                    v-model="company.nif"
                    name="nif"
                    v-mask="'##############'"
                    :state="getValidationState(nif)"
                  />
                  <b-form-invalid-feedback>{{ nif.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            
            <div class="col-md-6">
              <validation-provider
                name="E-mail"
                :rules="{ required: true, email: true }"
                v-slot="email"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="E-mail"
                  label-for="email"
                >
                  <b-form-input
                    for="email"
                    v-model="company.email"
                    id="input-company-name"
                    name="email"
                    :state="getValidationState(email)"
                  />
                  <b-form-invalid-feedback>{{ email.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            
            <div class="col-md-6">
              <validation-provider
                name="Telefone"
                :rules="{ numeric: true }"
                v-slot="phone"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="Telefone"
                  label-for="phone"
                >
                  <b-form-input
                    v-model="company.phone"
                    id="input-company-phone"
                    name="phone"
                    v-mask="'############'"
                    :state="getValidationState(phone)"
                  />
                  <b-form-invalid-feedback>{{ phone.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </div>
  
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                label="Logradouro"
                label-for="input-company-street"
              >
                <b-form-input
                  v-model="company.street"
                  id="input-company-street"
                  name="street"
                />
              </b-form-group>
            </div>
            
            <div class="col-md-6">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                label="Número"
                label-for="number"
              >
                <b-form-input
                  v-model="company.number"
                  id="input-company-number"
                />
              </b-form-group>
            </div>
            
            <div class="col-md-6">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                label="Complemento"
                label-for="complement"
              >
                <b-form-input
                  v-model="company.complement"
                  id="input-company-complement"
                />
              </b-form-group>
            </div>
            
            <div class="col-md-6">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                label="Bairro"
                label-for="district"
              >
                <b-form-input
                  v-model="company.district"
                  id="input-company-district"
                />
              </b-form-group>
            </div>
            
            <div class="col-md-6">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                label="Cidade"
                label-for="city"
              >
                <b-form-input
                  v-model="company.city"
                  id="input-company-city"
                />
              </b-form-group>
            </div>
            
            <div class="col-md-6">
              <validation-provider
                name="UF"
                :rules="{ alpha: true, min: 2, max: 2 }"
                v-slot="uf"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="UF"
                  label-for="uf"
                >
                  <b-form-input
                    v-model="company.uf"
                    id="input-company-uf"
                    name="uf"
                    :state="getValidationState(uf)"
                    v-mask="'AA'"
                  />
                  <b-form-invalid-feedback>{{ uf.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            
            <div class="col-md-6">
              <validation-provider
                name="CEP"
                :rules="{ min: 9, max: 9 }"
                v-slot="zipcode"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  label="CEP"
                  label-for="zipcode"
                >
                  <b-form-input
                    v-model="company.zipcode"
                    id="input-company-zipcode"
                    v-mask="'######-###'"
                    name="zipcode"
                    :state="getValidationState(zipcode)"
                  />
                  <b-form-invalid-feedback>{{ zipcode.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            
            <div class="col-md-6">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                label="País"
                label-for="country"
              >
                <b-form-input
                  v-model="company.country"
                  id="input-company-country"
                />
              </b-form-group>
            </div>
          </div>
          
          <template v-slot:modal-footer>
            <b-button size="sm" variant="success" type="submit">
              Salvar
            </b-button>
            <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-user')">
              Cancelar
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </Layout>
</template>
