<script>
  import { getCompanyOrders } from '@/api/Companies.js'
  import { Order } from "@/models/Order";
  
  export default {
    name: "Orders",
    props: ['companyId'],
    data() {
      return {
        orders: []
      };
    },
    created () {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this;
        getCompanyOrders(this.$route.params.id).then(response => {
          vm.orders = response.map(item => {
            return new Order(item)
          });
        });
      },
    },
    computed: {
      hasItems: function () {
        return this.orders.length > 0
      }
    }
  }
</script>
<template>
  <table class="table table-striped mb-0">
    <thead>
    <tr>
      <th class="border-top-0">#</th>
      <th class="border-top-0">Itens</th>
      <th class="border-top-0 text-right">Valor</th>
      <th class="border-top-0 text-center">Status</th>
      <th class="border-top-0 min">Vencimento</th>
      <th class="border-top-0"></th>
    </tr>
    </thead>
    <tbody>
    <tr v-if="!hasItems">
      <td colspan="100%" class="text-center">
        <p class="py-4 mb-0">Nenhum resultado encontrado.</p>
      </td>
    </tr>
    <tr v-else v-for="item in orders" v-bind:key="item.id">
      <th scope="row">
        {{ item.id }}
      </th>
      <td>{{ item.description }}</td>
      <td class="text-right">{{ $n(item.final_amount, 'currency', item.currency)}}</td>
      <td class="text-center">
        <span :class="item.class">{{ $t(`global.status.${item.status}`) }}</span>
      </td>
      <td class="min">{{ $d(item.due, 'short') }}</td>
      <td class="min">
        <button
          type="button"
          class="btn btn-light btn-sm btn-rounded"
          @click="$router.push(`/orders/${item.id}`)"
        >
          <i class="mdi mdi-eye" />
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</template>
