<script>
  import { getLimitCategories } from '@/api/LimitCategories.js'
  import { getCompanyLimits } from '@/api/Companies.js'
  import { CompanyLimit } from "@/models/CompanyLimit";
  
  export default {
    name: "Limits",
    props: ['companyId'],
    data() {
      return {
        items: new CompanyLimit(),
        categories: [],
      };
    },
    created () {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this;
        
        getLimitCategories().then(response => {
          vm.categories = response;
          this.loadItems();
        });
      },
      loadItems(){
        let vm = this;

        getCompanyLimits(this.companyId).then(response => {
          vm.items = new CompanyLimit(response);
        });
      }
    }
  }
</script>
<template>
  <div>
    <div class="mb-25" body-style="padding: 0;" shadow="never" style="border-left: none; border-right: none"  v-for="category in categories" v-show="category.visible === 1" v-bind:key="category.id">
      <h5 class="mb-3 mt-3 font-size-14">
        {{ category.name }}
      </h5>
    
      <table class="table table-limits mb-5">
        <tbody v-for="limit in category.limits" v-bind:key="limit.id" v-show="limit.visible === 1">
        <tr>
          <td class="info-text">
            {{ $t(`content.limits.${limit.slug}`) }}
          </td>
          <td v-if="limit.type === 'integer'">
            <span v-if="items[limit.slug] === -1">
              Ilimitado
            </span>
            <span v-else>
              <span v-if="items[limit.slug] > 0">
                {{ items[limit.slug] }} <small v-if="limit.sufix">{{ $t(`content.limits.sufix.${limit.sufix}`) }}</small>
              </span>
              <small class="info-text" v-else>Indisponível</small>
            </span>
          </td>
          <td v-else>
            <i v-if="items[limit.slug] == 1" class="mdi mdi-check"></i>
            <small class="info-text" v-else> Indisponível</small>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style>
  .table-limits tbody {
    width: 33.33%;
    float: left;
    border: none !important;
  }

  .table-limits tbody tr {
    display: block;
    width: 100%;
  }

  .table-limits tbody tr td {
    display: inline-block;
    width: 50%;
  }
</style>
