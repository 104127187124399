<script>
  import { getCompanyCreditCards } from '@/api/Companies.js'

  export default {
    name: "CreditCards",
    props: ['companyId'],
    data() {
      return {
        creditcards: []
      };
    },
    created () {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this;
        getCompanyCreditCards(this.$route.params.id).then(response => {
          vm.creditcards = response
        });
      },
    },
    computed: {
      hasItems: function () {
        return this.creditcards.length > 0
      }
    }
  }
</script>
<template>
  <table class="table table-striped mb-0">
    <thead>
    <tr>
      <th class="border-top-0">#</th>
      <th class="border-top-0">Nome</th>
      <th class="border-top-0">Número</th>
      <th class="border-top-0">Data</th>
      <th class="border-top-0"></th>
    </tr>
    </thead>
    <tbody>
    <tr v-if="!hasItems">
      <td colspan="100%" class="text-center">
        <p class="py-4 mb-0">Nenhum resultado encontrado.</p>
      </td>
    </tr>
    <tr v-else v-for="item in creditcards" v-bind:key="item.id">
      <th scope="row">
        {{ item.id }}
      </th>
      <td>
        {{ item.alias }}
      </td>
      <td>
        {{ item.number }}
      </td>
      <td class="min">
        {{ $d(new Date(item.created_at), 'long') }}
      </td>
      <td class="min">
        <button
          type="button"
          class="btn btn-light btn-sm btn-rounded"
        >
          <i class="bx bx-trash" />
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</template>
