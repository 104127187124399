<script>
  import { getCompanyContacts } from '@/api/Companies.js'

  export default {
    name: "Contacts",
    props: ['companyId'],
    data() {
      return {
        contacts: []
      };
    },
    created () {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this;
        getCompanyContacts(this.$route.params.id).then(response => {
          vm.contacts = response
        });
      },
    },
    computed: {
      hasItems: function () {
        return this.contacts.length > 0
      }
    }
  }
</script>
<template>
  <table class="table table-striped mb-0">
    <thead>
    <tr>
      <th class="border-top-0">#</th>
      <th class="border-top-0">Nome</th>
      <th class="border-top-0">Função</th>
      <th class="border-top-0">E-mail</th>
      <th class="border-top-0">Telefone</th>
      <th class="border-top-0"></th>
    </tr>
    </thead>
    <tbody>
    <tr v-if="!hasItems">
      <td colspan="100%" class="text-center">
        <p class="py-4 mb-0">Nenhum resultado encontrado.</p>
      </td>
    </tr>
    <tr v-else v-for="item in contacts" v-bind:key="item.id">
      <th scope="row">
        {{ item.id }}
      </th>
      <td>{{ item.name }}</td>
      <td>{{ $t(`global.roles.${ item.role }`) }}</td>
      <td>{{ item.email}}</td>
      <td>{{ item.phone}}</td>
      <td class="min">
        <button
          type="button"
          class="btn btn-light btn-sm btn-rounded"
        >
          <i class="bx bx-trash" />
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</template>
